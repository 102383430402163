body {
    margin      : 0.7rem;
    padding     : 0;
    box-sizing  : border-box;
    font-family : 'Montserrat', sans-serif;
    /* max-width   : 100%; */
    /* overflow-x  : hidden; */
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  ul,
  li {
    margin      : 0;
    padding     : 0;
  }